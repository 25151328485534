@import '~react-gosuslugi/global_sass/vars';
@import '~react-gosuslugi/global_sass/mixin';
@import "~react-gosuslugi/global_sass/media";

html, body {
  @include font($general-ff-ekibastuz, $general-font-weight, $general-font-size);
  height: 100%;
  margin: 0; }

*, *:before, *:after {
  box-sizing: border-box; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  white-space: pre-wrap; }

p {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  margin-top: 0; }

h1 {
  font-size: 2.5rem; // 40px
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

h2 {
  font-size: 2rem; // 32px
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

h3 {
  font-size: 1.5rem; // 24px
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

h4 {
  font-size: 1.25rem; // 20px
  line-height: 1.25rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

h5 {
  font-size: 1rem; // 16px
  line-height: 1.25rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

h6 {
  font-size: 0.8750rem; // 14px
  line-height: 1.25rem;
  margin-top: 0;
  margin-bottom: 1.25rem; }

legend {
  width: 100%;
  border: 0; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

.right_arrow, .left_arrow, .top_arrow, .bottom_arrow {
  position: relative;
  @include inline-block(middle);
  min-width: 8px;
  width: 8px;
  height: 8px;
  padding: 0!important;
  border-right: 1px solid $general-font-color;
  border-bottom: 1px solid $general-font-color; }
.right_arrow {
  transform: rotate(-45deg);
  margin-left: 5px; }
.left_arrow {
  transform: rotate(135deg);
  margin-right: 10px;
  margin-bottom: 2px; }
.top_arrow {
  transform: rotate(225deg);
  margin-left: 10px;
  margin-bottom: -2px; }
.bottom_arrow {
  transform: rotate(45deg);
  margin-left: 10px;
  margin-bottom: 6px; }

.toggleCaption-wrapper {
  pre {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 20px; } }

$toggleCaption_color: color('primary', 'light');
$toggleCaption_color-hover: color('primary','dark');
.toggleCaption {
  @include inline-block();
  color: $toggleCaption_color;
  border-bottom: 1px dashed $toggleCaption_color;
  cursor: pointer;
  &:hover {
    color: $toggleCaption_color-hover;
    border-color: $toggleCaption_color-hover; }
  & span {
    border-color: $toggleCaption_color; } }

$certificate-ff: $general-ff-ekibastuz;
$certificate-caption-fw: $general-font-weight-bold;
$certificate-text-fw: $general-font-weight;
$certificate-text-fsz: 18px;
$certificate-text-color:  color('text');
.certificate__text {
  padding-left: 20px;
  @include font($certificate-ff, $certificate-caption-fw, $certificate-text-fsz, $certificate-text-color);
  word-break: break-all;

  & span {
    @include font($certificate-ff, $certificate-text-fw, $certificate-text-fsz, $certificate-text-color); } }

$array-border-color: #cdd1d4;
.input-wrapper {
  display: flex;
  flex-direction: column-reverse;
  & .field-array-of-object {
    padding: 0 20px 0 20px;
    border: 1px solid $array-border-color;
    position: relative;
    & .array-wrapper {
      padding: 20px 0 0 0;
      border-top: 1px solid $array-border-color;
      @include small {
        padding: 40px 0 0 0; }
      &:first-child {
        border: none; } }
    & legend {
      border: none;
      margin-bottom: 20px;
      padding: 0; }
    & .addBtn-wrapper {
      padding: 20px 0;
      & .button {
        display: flex;
        margin: 0;
        align-items: center;
        color: color('primary'); } }
    & + label {
      position: relative;
      top: 0;
      left: 0;
      @include font($general-ff-ekibastuz, $general-font-weight-bold, 18px); } } }

.overflowHidden {
  overflow: hidden; }

.sr-only {
  display: none; }

.rbc-time-slot:hover {
  background-color: cadetblue; }

.react-bs-table table td, .react-bs-table table th {
  white-space: normal!important; }

.field-error .drop-zone {
  border-color: color('danger'); }

.panel-body {
  word-wrap: break-word; }

.uslugas-show {
  @include font($general-ff-ekibastuz);
  & span {
    font-weight: bold;
    &.default {
      color: black; }
    &.warning {
      color: color('warning'); }
    &.danger, &.error {
      color: color('danger'); }
    &.success {
      color: color('success'); }
    &.info {
      color: color('info'); }
    &.primary {
      color: color('primary'); } } }

.table-date {
  &.default {
      color: black; }
  &.warning {
    color: color('warning'); }
  &.danger, &.error {
    color: color('danger'); }
  &.success {
    color: color('success'); }
  &.info {
    color: color('info'); }
  &.primary {
    color: color('primary'); } }

.float-right {
  float: right; }

.MySticky {
  z-index: 99;
  position: relative; }

a {
  text-decoration: none;
  &:hover {
    text-decoration: none; }
  &:focus {
    outline: none; } }

.radio, .checkbox {
  margin-bottom: 20px;
  margin-top: 0; }

label {
  margin-bottom: 8px;
  color: color('text');
  display: inline-block;
  max-width: 100%;
  font-weight: bold; }

.general-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  & .content-wrapper {
    flex: 1;
    margin: 40px 0;
    & .card-caption {
      margin-bottom: 20px;
      align-items: flex-start; }
    & .usluga-card .card-caption {
      margin-bottom: 0; } } }

.btn-question {
  z-index: 1000; }

.dropdown.button-group .button:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-radius: 4px; }

.alert-event-wrapper .alert {
  align-items: flex-start;
  & .alert-event-wrapper-btn {
    display: flex;
    justify-content: space-between; } }

.overlay {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1; }

$background-color-hover-dark: 20%;
$background-color-warning: color('warning','light','light');
$background-color-danger: color('danger','light','light');
$background-color-info: color('info','light','light');
$background-color-success: color('success','light','light');
$background-color-primary: color('primary','light','light');
.background-color {
  &--default {
    background-color: color('white');
    &:hover {
      background-color: darken(color('white'),5%); } }
  &--warning {
    background-color: $background-color-warning;
    &:hover {
      background-color: darken($background-color-warning,$background-color-hover-dark); } }
  &--danger {
    background-color: $background-color-danger;
    &:hover {
      background-color: darken($background-color-danger,$background-color-hover-dark); } }
  &--success {
    background-color: $background-color-success;
    &:hover {
      background-color: darken($background-color-success,$background-color-hover-dark); } }
  &--info {
    background-color: $background-color-info;
    &:hover {
      background-color: darken($background-color-info,$background-color-hover-dark); } }
  &--primary {
    background-color: $background-color-primary;
    &:hover {
      background-color: darken($background-color-primary,$background-color-hover-dark); } } }

.dashboard__items-wrapper .dashboard__item .dashboard__item-link .dashboard__col-date span.color {
  &--default {
    color: color('white'); }
  &--warning {
    color: $background-color-warning; }
  &--danger {
    color: red; }
  &--success {
    color: $background-color-success; }
  &--info {
    color: $background-color-info; }
  &--primary {
    color: $background-color-primary; } }

$panel-color-hover-dark: 20%;
$panel-color-hover-bg-dark: 10%;
$panel-color--default-color: darken(color('white'),5%);
$panel-color--default-color-hover: darken(color('white'),10%);
$panel-color--primary-color: color('primary');
$panel-color--primary-color-hover: color('primary', 'light');
.panel-wrapper.panel-color {
  &--default {
    border-color: $panel-color--default-color-hover;
    > .panel__header {
      background-color: $panel-color--default-color;
      border-color: $panel-color--default-color-hover;
      &:hover {
        background-color: $panel-color--default-color-hover; } } }
  &--warning {
    border-color: darken($background-color-warning,$background-color-hover-dark);
    > .panel__header {
      color: darken(color('warning'), $panel-color-hover-dark);
      background-color: $background-color-warning;
      border-color: darken($background-color-warning,$background-color-hover-dark);
      &:hover {
        background-color: darken($background-color-warning,$panel-color-hover-bg-dark); } } }
  &--danger {
    border-color: darken($background-color-danger,$background-color-hover-dark);
    > .panel__header {
      color: darken(color('danger'), $panel-color-hover-dark);
      background-color: $background-color-danger;
      border-color: darken($background-color-danger,$background-color-hover-dark);
      &:hover {
        background-color: darken($background-color-danger,$panel-color-hover-bg-dark); } } }
  &--success {
    border-color: darken($background-color-success,$background-color-hover-dark);
    > .panel__header {
      color: darken(color('success'), $panel-color-hover-dark);
      background-color: $background-color-success;
      border-color: darken($background-color-success,$background-color-hover-dark);
      &:hover {
        background-color: darken($background-color-success, $panel-color-hover-bg-dark); } } }
  &--info {
    border-color: darken($background-color-info,$background-color-hover-dark);
    > .panel__header {
      color: darken(color('info'), $panel-color-hover-dark);
      background-color: $background-color-info;
      border-color: darken($background-color-info,$background-color-hover-dark);
      &:hover {
        background-color: darken($background-color-info, $panel-color-hover-bg-dark); } } }
  &--primary {
    border-color: $panel-color--primary-color;
    > .panel__header {
      color: color('white');
      background-color: $panel-color--primary-color;
      border-color: $panel-color--primary-color;
      &:hover {
        background-color: $panel-color--primary-color-hover; } } } }

.panel-wrapper {
  .panel__header {
    display: flex;
    flex-direction: row;
    &-text {
      flex: 1; }
    &-info {
      flex: 0; } } }

.queries_date {
  margin-left: 1.3rem; }

.panel-arrow {
  width: 10px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    bottom: auto;
    left: auto;
    width: 10px;
    height: 10px;
    margin-top: -2px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000; }
  &.arrow-top {
    &:after {
      transform: rotate(-135deg); } }
  &.arrow-bottom {
    &:after {
      transform: rotate(45deg);
      margin-top: -8px; } } }

.map-icon-marker {
  background: url("../../images/map-marker.svg") no-repeat;
  background-size: contain;
  width: 50px!important;
  margin-left: -25px!important;
  height: 100px!important;
  margin-top: -50px!important; }

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 0;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  & .progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: color('primary');
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    transition: width .6s ease;
    &-danger {
      background: color('danger'); }
    &-info {
      background: color('info'); }
    &-success {
      background: color('success'); }
    &-warning {
      background: color('warning'); }
    & .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0; } } }

.panel__header-text p {
  margin-bottom: 0!important; }

.label {
  display: block;
  cursor: pointer;
  text-align: left;
  margin-bottom: 5px; }

.service-wrapper {
  width: 100%; }

.wrapper-table .table tr {
  &.warning {
    cursor: pointer;
    background: $background-color-warning;
    transition: all .6s ease;
    &:hover {
      background-color: darken($background-color-warning, $panel-color-hover-bg-dark); } }
  &.danger {
    cursor: pointer;
    background: $background-color-danger;
    transition: all .6s ease;
    &:hover {
      background-color: darken($background-color-danger, $panel-color-hover-bg-dark); } }
  &.info {
    cursor: pointer;
    background: $background-color-info;
    transition: all .6s ease;
    &:hover {
      background-color: darken($background-color-info, $panel-color-hover-bg-dark); } }
  &.success {
    cursor: pointer;
    background: $background-color-success;
    transition: all .6s ease;
    &:hover {
      background-color: darken($background-color-success, $panel-color-hover-bg-dark); } }
  &.primary {
    cursor: pointer;
    background: $background-color-primary;
    transition: all .6s ease;
    &:hover {
      background-color: darken($background-color-primary, $panel-color-hover-bg-dark); } }
  & .table_comment {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    padding: 0.75rem 0.75rem 0 0.75rem;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
    border-bottom: none;
    &:hover {
      display: table-cell; } }
  & .table_data {
    padding: 0.75rem 3px; } }

.wrapper-img-compare img {
  width: 100%;
  height: auto;
  display: block;
  transition: all 1s ease;
  &:hover {
    transform: scale(1.5); } }

.react-viewer-toolbar {
  height: 35px;
  & li {
    width: 35px;
    height: 35px;
    line-height: 35px; } }

.react-viewer-list {
  & li {
    width: 40px;
    margin-right: 5px;
    &.active {
      border: 1px solid red; } } }

.nav-list__dropdown.nav-list__dropdown-item {
  margin-right: 30px; }

.passport-select option {
  white-space: normal; }


@media only screen and (max-width: 1200px) {
  html {
    font-size: 15px; } }

@media only screen and (max-width: 992px) {
  html {
    font-size: 14px; } }

@media only screen and (max-width: 768px) {
  html {
    font-size: 13px; }
  .react-viewer-canvas {
    overflow: scroll!important; } }
