@import "../styles/variables";

.main {
    .organizations {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    
        &-header {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 4 * $baseStep;
    
            h1 {
                font-size: $baseFontSize * 2;
                font-weight: bold;
                color: $mainColor;
                text-transform: uppercase;
    
                span {
                    color: $accentColor;
                    margin-left: $baseStep  * 2;
                }
            }
        }

        &__main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                color: #555555;
                text-align: center;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
            margin: 0;

            @media(min-width: 768px) { 
                width: auto;
            }
        }

        &__item {
            list-style-type: none;
            border: $baseStep / 4 solid $borderColor;
            border-radius: $baseStep;
            overflow: hidden;
            margin-bottom: $baseStep * 4;
            transition: transform .3s ease;

            &:hover {
                transform: translateY(-5%);
            }

            &:last-child {
                margin-bottom: 0;
            }

            button {
                width: 100%;
                appearance: none;
                border: none;
                padding: $baseStep * 4 $baseStep * 6;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                transition: background-color .3s ease;
                text-align: left;
                background-color: $accentoHoverColor;

                &:hover {
                    background-color: white;
                }
            }

            img {
                display: block;
            }

            span {
                display: block;

                &.img {
                    margin-right: $baseStep * 4;
                    background-color: $fontColor;
                    border-radius: 100 * $baseStep;
                }

                &.titles {
                    :first-child {
                        font-size: $baseFontSize * 1.15;
                        color: inherit;
                        margin-bottom: $baseStep * 2;
                    }

                    :nth-child(2) {
                        font-size: $baseFontSize;
                        color: $borderColor;
                    }

                    :last-child {
                        margin-bottom: 0;
                    }
                }

                &.arrow {
                    position: relative;
                    width: $baseStep * 6;
                    height: $baseStep * 6;
                    background-color: #e6f1f9;
                    margin-left: auto;
                    border-radius: 100 * $baseStep;

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        border-top: 1px solid #0a40b3;
                        border-right: 1px solid #0a40b3;
                        width: 30%;
                        height: 30%;
                        top: 50%;
                        right: 55%;
                    }

                    &:after {
                        transform: translateY(-50%) translateX(50%) rotate(45deg);
                    }
                }
            }

            @media(min-width: 768px) {
                min-width: $baseStep * 100;
            }
        }

        &-footer {
            display: flex;
            justify-content: center;
            padding: $baseStep * 4 0;
            background-color: $footerBackgorindcolor;
            margin-top: $baseStep * 8;

            .container {
                display: flex;
                justify-content: space-between;
            }

            &__item {
                display: flex;
                align-items: center;

                &_wide {
                    flex-direction: row-reverse;
                }
            }

            &__icon {
                height: $baseStep * 18;

                img {
                    display: block;
                    height: 100%;
                }
            }

            &__text {
                margin: 0 $baseStep * 4;
            }
        }
    }
}
