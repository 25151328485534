@font-face {
    font-family: "Helvetica Neue";
    src: url("~react-gosuslugi/dist/fonts/HelveticaNeue.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("~react-gosuslugi/dist/fonts/HelveticaNeueBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Ekibastuz";
    src: url("~react-gosuslugi/dist/fonts/Ekibastuz-Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Ekibastuz";
    src: url("~react-gosuslugi/dist/fonts/Ekibastuz-Bold.otf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Ekibastuz";
    src: url("~react-gosuslugi/dist/fonts/Ekibastuz-Light.otf");
    font-weight: 300;
    font-style: normal;
}
