.cite {
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	color: #fff;
	width: 100%;
	font-size: larger;
	opacity: -1;
	height: 25rem;
	justify-content: center;
	display: flex;
	flex-direction: column;
	padding: 2rem;

	@media only screen and (min-device-width : 800px){
		transition: opacity .3s ease 0s;
	}

	&__author {
		display: flex;
		justify-content: flex-end;
		font-style: italic;
		text-align: right;
	}
}
