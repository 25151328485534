$baseStep: 4px;
$mainColor: #0860a6;
$accentColor: #e90e3f;
$accentColorSecondary: #b90a63;
$fontColor: white;
$baseFontSize: 1rem;
$borderColor: #cccccc;
$accentoHoverColor: #eeeeee66;
$footerBackgorindcolor: #e6ebec;
$mainBgColor: rgba(56, 77, 155, 0.4);
