@import "../styles/variables";

$mainClass: ".welcome";

body {
    &.main {
		#{$mainClass} {
			color: $fontColor;
			width: auto;
			display: flex;
			flex-direction: column;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			width: 100%;
			height: 100%;

			&__main {
				opacity: 0;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				background-color: transparent;
				backdrop-filter: none;
				transition: opacity 1s ease-in-out .1s;

				@media (min-width: 992px) {
					position: relative;
					flex-direction: column;
					width: 25rem;
					padding: 4rem 0;
					align-items: center;
					background-color: $mainBgColor;
					backdrop-filter: blur(15px);
				}

				&-arm {
					@media (min-width: 992px) {
						width: 26rem;
					}
				}
			}

			&__header {
				width: 100%;
				margin: 0;
				padding: 2rem 1rem;
				text-align: center;
				align-self:center;
				text-transform: uppercase;
				margin: 0;
				background-color: $mainBgColor;
				backdrop-filter: blur(15px);
				white-space: nowrap;

				i {
					font-style: normal;
					color: $accentColor;
				}

				@media (min-width: 992px) {
					background-color: transparent;
					backdrop-filter: none;
				}
			}

			&__body {
				flex: 1;
				width: 100%;
				padding: 0;
				display: flex;
				flex-direction: column;
			}

			&__content {
				display: flex;
				justify-content: center;
				align-content: center;
				flex: 1;
				align-items: center;
				text-align: center;

				@media (min-width: 768px) {

				}

				@media (min-width: 992px) {
					width: 25rem;
					align-self: center;
				}

				&:hover {
					@media only screen
					and (min-device-width : 800px){
						img {
							position: relative;
							opacity: -1;
						}

						.cite {
							position: absolute;
							opacity: 1;
						}
					}
				}

				img {
					width: max-content;
					padding: 2rem;
					position: absolute;
					transition: opacity .3s ease 0s;
					max-height: 100%;
					@media (max-device-width: 992px) {
						filter: drop-shadow(0px 0px 3px black);
					}
				}
			}

			&__action {
				flex: 0;
				background-color: $mainBgColor;
				backdrop-filter: blur(15px);

				@media (min-device-width: 992px) {
					background-color: transparent;
					backdrop-filter: none;
				}

				&-button {
					align-self: flex-end;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: center;
					align-content: space-around;
					align-items: center;
					height: auto;
					transition: background 0.3s ease;
					width: 100%;
					padding: 0.8rem 0;

					&:hover {
						@media (min-device-width : 992px) {
							cursor: pointer;
							background:#0f24359c;
						}
					}

					&_logo {
						width: 5rem;
						padding: $baseStep;
						margin-right: 1rem;

						&-arm {
							width: 8rem;
							background-color: white;
						}
					}

					&_text {
						order: 2;
						flex: 0 1 auto;
						align-self: auto;
						color: $fontColor;
						font-size: 1.5rem;
						line-height: $baseFontSize * 2;
						text-align: center;
						margin: revert;
						white-space: nowrap;
					}
				}
			}

			@media (min-width: 992px) {
				padding: 0 6rem;
				flex-direction: row;
			}

			&_bottom-right {
				@media (min-width: 992px) {
					align-items: flex-end;
					justify-content: flex-end;
				}

				#{$mainClass} {
					&__main {
							@media (min-width: 992px) {
								width: auto;
								flex-direction: row;
								height: auto;
								padding: 0;
								background: transparent;
							}
					}

					&__header {
						@media (min-width: 992px) {
							margin: 0;
							text-align: left;
							width: auto;
							padding: 2rem;
						}
					}

					&__body {
						@media (min-width: 992px) {
							width: auto;
						}
					}

					&__content {
						@media (min-width: 992px) {
							display: none;
						}
					}

					&__action {
						@media (min-width: 992px) {
							width: fit-content;
						}

						&-button {
							@media (min-width: 992px) {
							padding: 1rem 2rem;
							}
						}
					}
				}
			}

			&_top-right {
				padding: 0;
				align-items: flex-start;

				#{$mainClass} {
					&__main {
						@media (min-width: 992px) {
							width: auto;
							flex-direction: row;
							height: auto;
							padding: 0;
							background: transparent;
						}
					}

					&__header {
						@media (min-width: 992px) {
							margin: 0;
							text-align: left;
							width: auto;
							padding: 2rem;
						}
					}

					&__body {
						@media (min-width: 992px) {
							width: auto;
						}
					}

					&__content {
						@media (min-width: 992px) {
							display: none;
						}
					}

					&__action {
						@media (min-width: 992px) {
							width: fit-content;
						}

						&-button {
							@media (min-width: 992px) {
							padding: 1.4rem 2rem;
							}
						}
					}
				}
			}

			&__copyright {
				display: flex;
				align-items: end;
				font-size: 12px;
				padding: 20px;
				max-width: 469px;
				text-shadow: 0 0 4px #000000;

				@media (min-width: 992px) {
					height: 100%;
				}
			}
		}
    }
}
