@import "../../css/base/_base.sass";
@import "../../styles/fonts.scss";

@import "./styles/variables";

body {
    &.main {
        #root {
            height: 100%;
        }
        
        .container {
            width: 100%;
            height: 100%;
            padding: 0 $baseStep * 4;
            margin: 0 auto;

            @media(min-width: 576px) {
                width: 540px;
            }

            @media(min-width: 768px) {
                width: 720px;
            }

            @media(min-width: 992px) {
                width: 960px;
            }

            @media(min-width: 1200px) {
                width: 1140px;
            }
        }
    }
}
